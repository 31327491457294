import React, { useEffect, useRef, useState } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useHistory } from "react-router-dom";
import useSound from "use-sound";

import { Container } from "../../atoms/Container";
import GameSkeleton from "../../atoms/GameSkeleton";
import {
  GridcellPerimeterInput,
  GridcellPerimeter,
  GridPerimeter,
  BigBoxComponent,
} from "../../gamecomponents/GridcellPerimeter";
import { checkPerimeterResult } from "../../../gameFunctions/perimeter";
import { useStore } from "../../../store";
import { transition } from "../../organisms/GameControls";
import { theme } from "../../../theme";
import GameInterface from "../../organisms/GameInterface";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { usePerimeter } from "../../../store/games/perimeter";
import CountDown from "../../atoms/CountDown";
import GameControls from "../../organisms/GameControls";
import MobileGameControls from "../../organisms/MobileGameControls";
import gameMusic from "../../../assets/audio/game.mp3";
import winSound from "../../../assets/audio/win.wav";
import failSound from "../../../assets/audio/fail.wav";
import BackButton from "../../atoms/BackButton";

const Perimeter = () => {
  const {
    games,
    setSelectedGame,
    selectedGame,
    soundSettingGame,
    soundSettingWin,
    soundSettingFail,
  } = useStore();
  const { xs, small } = useWindowDimensions();
  const { result, setResultPerimeter, stopped, setStopped, config } =
    usePerimeter();
  const [started, setStarted] = useState(false);
  const controls = useAnimation();
  const [open, setOpen] = useState(true);
  const [simplified, setSimplified] = useState(false);
  const [resultFeedback, setResultFeedback] = useState(undefined);
  const output = useRef();
  const input = useRef();
  const gameskeleton = useRef();
  const history = useHistory();
  const [startCountdown, setStartCountdown] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [playGameMusic, { stop }] = useSound(gameMusic, {
    volume: soundSettingGame,
  });
  const [playWinMusic] = useSound(winSound, { volume: soundSettingWin });
  const [playFailMusic] = useSound(failSound, { volume: soundSettingFail });
  const [clickable, setClickable] = useState(true);

  useEffect(() => {
    startCountdown && setClickable(false);
  }, [startCountdown]);

  useEffect(() => {
    !started && setClickable(true);
  }, [started]);

  const startGame = () => {
    playGameMusic();
    setResultPerimeter(undefined);
    setResultFeedback(undefined);
    setStarted(true);
    setStopped(false);
  };

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  useEffect(() => {
    if (result && resultFeedback === undefined) {
      setTimeout(() => setResultFeedback(true), 1000);
      playWinMusic();
    } else if (result === false && resultFeedback === undefined) {
      playFailMusic();
      setTimeout(() => setResultFeedback(false), 1000);
    }
    if (resultFeedback !== undefined) {
      setStarted(false);
      setShowControls(false);
    }
  }, [result, resultFeedback]);

  useEffect(() => {
    if (stopped && output.current && input.current) {
      setStopped(false);
      stop();
      setResultPerimeter(
        checkPerimeterResult(
          output.current.getBoundingClientRect(),
          input.current.getBoundingClientRect(),
          0
        )
      );
    }
  }, [stopped, started, setStopped, setResultPerimeter]);

  useEffect(() => {
    !xs && setOpen(true);
    if (startCountdown && result === undefined && xs) setOpen(false);
    if (result !== undefined && xs) setOpen(false);
  }, [xs, setOpen, startCountdown, result]);

  useEffect(() => {
    setSelectedGame(
      games.find((game) => game.link === history.location.pathname)
    );
    document.body.style.overflowX = "hidden";
    document.body.style.overflowY = "hidden";
  }, []);

  return (
    <Container center fluid>
      <BackButton />
      <Grid container alignItems="center">
        <Grid
          style={{
            height: xs ? "auto" : "100vh",
          }}
          item
          xs={12}
          md={8}
        >
          <GameSkeleton
            started={started}
            ref={gameskeleton}
            style={{
              marginTop: "5em",
            }}
          >
            <CountDown
              startGame={startGame}
              setStartCountdown={setStartCountdown}
              startCountdown={startCountdown}
              setResult={setResultPerimeter}
            />
            <BigBoxComponent small={small} />
            {gameskeleton.current && started && (
              <GridcellPerimeterInput
                stopGameMusic={stop}
                small={small}
                config={config}
                gameskeleton={gameskeleton.current}
                started={started}
                ref={input}
                controls={controls}
                simplified={simplified}
              />
            )}
            <GridPerimeter small={small} ref={output} />
            {Array.from(Array(small ? 49 : 100).keys()).map((i) => (
              <GridcellPerimeter small={small} key={i} />
            ))}
            {resultFeedback !== undefined && (
              <video
                autoPlay
                style={{
                  width: "100%",
                  height: "auto",
                  position: "absolute",
                  top: 0,
                  zIndex: 5,
                }}
                loop
                muted
                playsInline
              >
                <source
                  src={`/${resultFeedback ? "win" : "lose"}.mp4`}
                  type="video/mp4"
                />
              </video>
            )}
          </GameSkeleton>
        </Grid>
        <Grid item xs={4}>
          <motion.div
            transition={transition}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: xs ? 20 : 1,
              overflowY: "auto",
              height: "100vh",
              height: "-webkit-fill-available",
            }}
            exit={{ x: 0, transition: { delay: 0.5 } }}
            initial={{
              x: 0,
              width: xs ? (open ? "100vw " : "75px") : "34vw",
              height: "-webkit-fill-available",
              height: xs ? (open ? "100vh" : "40px") : "100vh",
              backgroundColor: xs
                ? open
                  ? theme.colors.primary
                  : theme.colors.secondary
                : theme.colors.primary,
              boxShadow: `0 5px 0 ${theme.colors.secondaryDarker}`,
            }}
            animate={{
              x: xs ? (open ? 0 : -20) : 0,
              y: xs ? (open ? 0 : 20) : 0,
              height: xs ? (open ? "100vh" : "40px") : "100vh",
              height: xs ? (open ? "-webkit-fill-available" : "40px") : "100vh",
              width: xs ? (open ? "100vw " : "75px") : "34vw",
              backgroundColor: xs
                ? open
                  ? theme.colors.primary
                  : theme.colors.secondary
                : theme.colors.primary,
              boxShadow: `0 5px 0 ${theme.colors.secondaryDarker}`,
            }}
          >
            <AnimatePresence exitBeforeEnter>
              {showControls && !xs ? (
                <GameControls
                  simplified={simplified}
                  simplify={() => setSimplified(true)}
                  type={selectedGame?.type}
                  setShowControls={setShowControls}
                  onClick={() => {
                    if (started) {
                      setStopped(true);
                      controls.stop("move");
                    }
                  }}
                />
              ) : (
                <GameInterface
                  setShowControls={setShowControls}
                  started={started}
                  result={result}
                  startGame={() => {
                    setResultPerimeter(undefined);
                    setResultFeedback(undefined);
                    setShowControls(true);
                    setStartCountdown(true);
                  }}
                  simplify={() => setSimplified(true)}
                  setOpen={setOpen}
                  open={open}
                  simplified={simplified}
                />
              )}
            </AnimatePresence>
          </motion.div>
        </Grid>
        {xs && (
          <MobileGameControls
            clickable={clickable}
            setSimplify={setSimplified}
            simplified={simplified}
            simplify={() => setSimplified(true)}
            onClick={() => {
              if (started) {
                setStopped(true);
                controls.stop("move");
              }
            }}
            startGame={() => {
              setResultPerimeter(undefined);
              setResultFeedback(undefined);
              setShowControls(true);
              setStartCountdown(true);
            }}
            started={started}
            result={result}
            type={selectedGame?.type}
          />
        )}
      </Grid>
    </Container>
  );
};

export default Perimeter;
