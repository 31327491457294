import React, { useEffect } from "react";
import { motion } from "framer-motion";

import { theme } from "../../theme";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const variants = {
  move: {
    height: "0%",
  },
  reset: {
    height: "100%",
    transition: { duration: 0.5 },
  },
};

const GridcellStopZone = React.forwardRef(
  (
    {
      started,
      resultFeedback,
      setresultFeedback,
      simplified,
      controls,
      visible,
      setVisible,
      setResultStopZone,
      result,
      setShowControls,
    },
    ref
  ) => {
    const { small } = useWindowDimensions();
    useEffect(() => {
      result !== undefined &&
        setTimeout(() => {
          setresultFeedback(result);
        }, 2000);
    }, [result]);

    useEffect(() => {
      let check;
      if (started) {
        controls.start("move");
        setTimeout(() => {
          setVisible(false);
        }, 1.3 * 1000);

        check = setTimeout(() => {
          setVisible(true);
          if (ref.current.getBoundingClientRect().height === 0)
            setResultStopZone(false);
          setShowControls(false);
        }, 4000);
      }

      return () => clearTimeout(check);
    }, [started]);

    useEffect(() => {
      if (resultFeedback !== undefined) controls.start("reset");
    }, [resultFeedback]);

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: !visible ? "rgba(190, 223, 255, 1)" : "#1360f0",
        }}
      >
        <motion.div
          animate={{
            top: small ? (simplified ? "29%" : "23%") : simplified ? 120 : 90,
          }}
          style={{
            backgroundColor: theme.colors.primary,
            height: 5,
            position: "absolute",
            zIndex: 5,
            width: "100%",
          }}
        />
        <div
          style={{
            backgroundColor: theme.colors.primary,
            height: 5,
            marginTop: 40,
            position: "absolute",
            zIndex: 5,
            width: "100%",
          }}
        />
        <motion.div
          ref={ref}
          animate={controls}
          transition={{
            duration: 4,
            ease: [0.32, 0.36, 0.57, 0.53],
          }}
          variants={variants}
          style={{
            height: "100%",
            position: "absolute",
            width: "100%",
            top: 0,
            background: "rgba(190, 223, 255, 1)",
            zIndex: 1,
          }}
        />
        {resultFeedback !== undefined && (
          <video
            autoPlay
            style={{
              width: "100%",
              height: "auto",
              position: "absolute",
              top: 0,
              zIndex: 5,
            }}
            loop
            muted
            playsInline
          >
            <source src={`/${result ? "win" : "lose"}.mp4`} type="video/mp4" />
          </video>
        )}
      </div>
    );
  }
);

export default GridcellStopZone;
