import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import useSound from "use-sound";

import { Container } from "../../atoms/Container";
import GameSkeleton from "../../atoms/GameSkeleton";
import { transition } from "../../organisms/GameControls";
import { theme } from "../../../theme";
import GameInterface from "../../organisms/GameInterface";
import GridcellCircumvent from "../../gamecomponents/GridcellCircumvent";
import { useStore } from "../../../store";
import { useCircumvent } from "../../../store/games/circumvent";
import CountDown from "../../atoms/CountDown";
import GameControls from "../../organisms/GameControls";
import gameMusic from "../../../assets/audio/game.mp3";
import winSound from "../../../assets/audio/win.wav";
import failSound from "../../../assets/audio/fail.wav";
import MobileGameControls from "../../organisms/MobileGameControls";
import BackButton from "../../atoms/BackButton";

const Circumvent = () => {
  const { result, setResultCircumvent } = useCircumvent();
  const {
    games,
    setSelectedGame,
    selectedGame,
    soundSettingGame,
    soundSettingWin,
    soundSettingFail,
  } = useStore();
  const [started, setStarted] = useState(false);
  const [time, setTime] = useState(15);
  let timeRef = useRef(15);
  let resultRef = useRef(undefined);
  const [playerPosition, setPlayerPosition] = useState({ x: 0, y: 0 });
  const [simplified, setSimplified] = useState(false);
  const [startCountdown, setStartCountdown] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [open, setOpen] = useState(true);
  const { xs, portrait } = useWindowDimensions();
  const history = useHistory();
  const [playGameMusic, { stop }] = useSound(gameMusic, {
    volume: soundSettingGame,
  });
  const [playWinMusic] = useSound(winSound, { volume: soundSettingWin });
  const [playFailMusic] = useSound(failSound, { volume: soundSettingFail });
  const [clickable, setClickable] = useState(true);

  useEffect(() => {
    startCountdown && setClickable(false);
  }, [startCountdown]);

  useEffect(() => {
    !started && setClickable(true);
  }, [started]);

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  useEffect(() => {
    if (result === true) {
      stop();
      playWinMusic();
    } else if (result === false) {
      stop();
      playFailMusic();
    }
  }, [result]);

  const startGame = () => {
    playGameMusic();
    setStarted(true);
    setResultCircumvent(undefined);
    resultRef.current = undefined;
    timeRef.current = simplified ? 17 : 15;
    setTime(simplified ? 17 : 15);
  };

  useEffect(() => {
    setSelectedGame(
      games.find((game) => game.link === history.location.pathname)
    );
    document.body.style.overflowY = "hidden";
    document.body.style.overflowX = "hidden";
  }, []);

  useEffect(() => {
    !xs && setOpen(true);
    if (startCountdown && result === undefined && xs) setOpen(false);
    if (result !== undefined && xs) setOpen(false);
  }, [xs, setOpen, startCountdown, result]);

  return (
    <Container center fluid>
      <BackButton />
      <Grid container alignItems="center">
        <Grid
          style={{
            height: xs ? "auto" : "100vh",
          }}
          item
          xs={12}
          md={8}
        >
          <GameSkeleton
            started={started}
            style={{
              marginTop: "5em",
            }}
          >
            <CountDown
              startGame={startGame}
              setStartCountdown={setStartCountdown}
              startCountdown={startCountdown}
              setResult={setResultCircumvent}
            />
            <GridcellCircumvent
              simplified={simplified}
              playerPosition={playerPosition}
              setPlayerPosition={setPlayerPosition}
              time={time}
              timeRef={timeRef}
              resultRef={resultRef}
              setTime={setTime}
              setStarted={setStarted}
              startGame={startGame}
              started={started}
              result={result}
              setShowControls={setShowControls}
            />
          </GameSkeleton>
        </Grid>
        <Grid item xs={4}>
          <motion.div
            transition={transition}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: xs ? 20 : 1,
              overflowY: "auto",
              height: "100vh",
              height: "-webkit-fill-available",
            }}
            exit={{ x: 0, transition: { delay: 0.5 } }}
            initial={{
              x: 0,
              width: xs ? (open ? "100vw " : "75px") : "34vw",
              height: "-webkit-fill-available",
              height: xs ? (open ? "100vh" : "40px") : "100vh",
              backgroundColor: xs
                ? open
                  ? theme.colors.primary
                  : theme.colors.secondary
                : theme.colors.primary,
              boxShadow: `0 5px 0 ${theme.colors.secondaryDarker}`,
            }}
            animate={{
              x: xs ? (open ? 0 : -20) : 0,
              y: xs ? (open ? 0 : 20) : 0,
              height: xs ? (open ? "100vh" : "40px") : "100vh",
              height: xs ? (open ? "-webkit-fill-available" : "40px") : "100vh",
              width: xs ? (open ? "100vw " : "75px") : "34vw",
              backgroundColor: xs
                ? open
                  ? theme.colors.primary
                  : theme.colors.secondary
                : theme.colors.primary,
              boxShadow: `0 5px 0 ${theme.colors.secondaryDarker}`,
            }}
          >
            <AnimatePresence exitBeforeEnter>
              {showControls && !xs ? (
                <GameControls
                  started={started}
                  setPlayerPosition={setPlayerPosition}
                  playerPosition={playerPosition}
                  simplified={simplified}
                  simplify={() => setSimplified(true)}
                  type={selectedGame?.type}
                  setShowControls={setShowControls}
                />
              ) : (
                <GameInterface
                  setShowControls={setShowControls}
                  started={started}
                  result={result}
                  startGame={() => {
                    setResultCircumvent(undefined);
                    setShowControls(true);
                    setStartCountdown(true);
                    setPlayerPosition({ x: 0, y: 0 });
                  }}
                  simplify={() => setSimplified(true)}
                  setOpen={setOpen}
                  open={open}
                  simplified={simplified}
                />
              )}
            </AnimatePresence>
          </motion.div>
        </Grid>
        {xs && (
          <MobileGameControls
            clickable={clickable}
            speed={portrait ? 5 : 5}
            setPlayerPosition={setPlayerPosition}
            playerPosition={playerPosition}
            simplify={() => setSimplified(true)}
            simplified={simplified}
            startGame={() => {
              setResultCircumvent(undefined);
              setShowControls(true);
              setStartCountdown(true);
              setPlayerPosition({ x: 0, y: 0 });
            }}
            started={started}
            result={result}
            type={selectedGame?.type}
          />
        )}
      </Grid>
    </Container>
  );
};

export default Circumvent;
