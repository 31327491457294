import React, { useState, useEffect } from "react";
import _ from "lodash";
import { AnimatePresence, motion } from "framer-motion";

import { theme } from "../../theme";
import { getRandomArbitrary } from "../../gameFunctions/calculate";
import { BigHeader } from "../atoms/typography";
import Grid from "@bit/mui-org.material-ui.grid";
import { MiddleButton } from "../../components/atoms/Button";
import { useColourCount } from "../../store/games/ColourCount";
import { useTweaks } from "use-tweaks";
import GameNumberInput from "../atoms/GameNumberInput";

const GridcellColourCount = ({
  started,
  resultFeedback,
  phase,
  setPhase,
  setShowAnswer,
  simplified,
}) => {
  const generateGame = () => {
    let array = Array.from(Array(100).keys());
    const reds = _.sampleSize(array, getRandomArbitrary(20, 25));
    let array2 = array.filter((item) => !reds.includes(item));
    const blues = _.sampleSize(array2, getRandomArbitrary(20, 25));

    return { blues, reds };
  };

  const getColor = (i) => {
    if (boxes.blues.includes(i)) return "blue";
    else if (boxes.reds.includes(i)) return theme.colors.primary;
  };

  const checkAnswer = () => {
    setShowAnswer(true);
    if (
      red
        ? parseInt(`${number1}${number2}`) === boxes.reds.length
        : parseInt(`${number1}${number2}`) === boxes.blues.length
    )
      setResultColourCount(true);
    else setResultColourCount(false);
  };

  const [red] = useState(Math.round(Math.random()));
  const [boxes, setBoxes] = useState(generateGame());
  const { setResultColourCount, result } = useColourCount();
  const [number1, setNumber1] = useState(0);
  const [number2, setNumber2] = useState(0);

  useEffect(() => {
    if (started) {
      if (!phase) {
        setPhase("showColour");
        setTimeout(() => {
          setPhase("showBoxes");
        }, 2 * 1000);
      }
      if (phase === "showBoxes") {
        setTimeout(
          () => {
            setPhase("input");
          },
          simplified ? 7000 : 5000
        );
      }
    }
  }, [started, phase]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: theme.colors.gradient,
      }}
    >
      <AnimatePresence>
        {phase === "showColour" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: red ? theme.colors.primary : "blue",
            }}
          />
        )}
      </AnimatePresence>
      {phase === "showBoxes" &&
        Array.from(Array(100).keys()).map((i) => (
          <div
            key={i}
            style={{
              width: "10%",
              height: "10%",
              border: `gray solid 1px`,
              float: "left",
              backgroundColor: getColor(i),
              boxSizing: "border-box",
            }}
          />
        ))}
      <Grid
        container
        style={{
          width: "100%",
          height: "100%",
          textAlign: "center",
          position: "absolute",
        }}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}>
          {phase === "input" && (
            <>
              <AnimatePresence exitBeforeEnter>
                <GameNumberInput
                  number1={number1}
                  number2={number2}
                  setNumber2={setNumber2}
                  setNumber1={setNumber1}
                  enter={() => {
                    phase === "input" && checkAnswer();
                  }}
                />
              </AnimatePresence>
            </>
          )}
        </Grid>
      </Grid>
      {resultFeedback !== undefined && (
        <video
          autoPlay
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            zIndex: 5,
          }}
          loop
          muted
          playsInline
        >
          <source
            src={`/${resultFeedback ? "win" : "lose"}.mp4`}
            type="video/mp4"
          />
        </video>
      )}
    </div>
  );
};

export default GridcellColourCount;
