import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import styled from "styled-components";

import arrowRight from "../../assets/images/arrow-right.svg";
import arrowLeft from "../../assets/images/arrow-left.svg";
import arrowUp from "../../assets/images/arrow-up.svg";
import arrowDown from "../../assets/images/arrow-down.svg";

const ArrowButton = styled.div`
  width: 50px;
  height: 50px;
  margin: 3px;
  background: ${(props) => props.theme.colors.secondary};
  box-shadow: 0 5px 0 ${(props) => props.theme.colors.secondaryDarker};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:active {
    box-shadow: none;
    transform: translateY(5px);
  }
`;

const ArrowMovement = ({
  setPlayerPosition,
  playerPosition,
  speed,
  started,
}) => {
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <ArrowButton
            style={{ marginBottom: 7 }}
            onClick={() => {
              started &&
                setPlayerPosition({
                  ...playerPosition,
                  y: playerPosition.y - speed,
                });
            }}
          >
            <img alt="up" src={arrowUp} width={30} height={30} />
          </ArrowButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center" direction="row">
          <ArrowButton
            onClick={() => {
              started &&
                setPlayerPosition({
                  ...playerPosition,
                  x: playerPosition.x - speed,
                });
            }}
          >
            <img alt="left" src={arrowLeft} width={30} height={30} />
          </ArrowButton>
          <ArrowButton
            onClick={() => {
              started &&
                setPlayerPosition({
                  ...playerPosition,
                  y: playerPosition.y + speed,
                });
            }}
          >
            <img alt="down" src={arrowDown} width={30} height={30} />
          </ArrowButton>
          <ArrowButton
            onClick={() => {
              started &&
                setPlayerPosition({
                  ...playerPosition,
                  x: playerPosition.x + speed,
                });
            }}
          >
            <img alt="right" src={arrowRight} width={30} height={30} />
          </ArrowButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ArrowMovement;
