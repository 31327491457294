import { useEffect } from "react";
import { useAvoid } from "../store/games/avoid";
import { useCalculate } from "../store/games/calculate";
import { useCircumvent } from "../store/games/circumvent";
import { useColourCount } from "../store/games/ColourCount";
import { useDimension } from "../store/games/dimension";
import { useExact } from "../store/games/exact";
import { useIdentical } from "../store/games/identical";
import { usePathfinder } from "../store/games/pathfinder";
import { usePerimeter } from "../store/games/perimeter";
import { useRepetition } from "../store/games/repetition";
import { useReplicate } from "../store/games/replicate";
import { useStopZone } from "../store/games/stopZone";
import { useTimeFreeze } from "../store/games/timeFreeze";
import { useTimeSplit } from "../store/games/timeSplit";
import { useVerticalDrop } from "../store/games/verticalDrop";

export default function useResultReset() {
  const { setResultAvoid } = useAvoid();
  const { setResultCalculate } = useCalculate();
  const { setResultCircumvent } = useCircumvent();
  const { setResultColourCount } = useColourCount();
  const { setResultDimension } = useDimension();
  const { setResultExact } = useExact();
  const { setResultIdentical } = useIdentical();
  const { setResultPathfinder } = usePathfinder();
  const { setResultPerimeter } = usePerimeter();
  const { setResultRepetition } = useRepetition();
  const { setResultReplicate } = useReplicate();
  const { setResultStopZone } = useStopZone();
  const { setResultTimeFreeze } = useTimeFreeze();
  const { setResultTimeSplit } = useTimeSplit();
  const { setResultVerticalDrop } = useVerticalDrop();

  useEffect(() => {
    setResultAvoid(undefined);
    setResultCalculate(undefined);
    setResultCircumvent(undefined);
    setResultColourCount(undefined);
    setResultDimension(undefined);
    setResultExact(undefined);
    setResultIdentical(undefined);
    setResultPathfinder(undefined);
    setResultPerimeter(undefined);
    setResultRepetition(undefined);
    setResultReplicate(undefined);
    setResultStopZone(undefined);
    setResultTimeFreeze(undefined);
    setResultTimeSplit(undefined);
    setResultVerticalDrop(undefined);
  }, []);

  return true;
}
