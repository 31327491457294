import React from "react";
import { motion } from "framer-motion";
import Grid from "@bit/mui-org.material-ui.grid";

import { BigHeader, ButtonText, Text } from "../atoms/typography";
import RedButton from "../atoms/RedButton";
import { Button } from "../atoms/Button";
import mouse from "../../assets/images/mouse.svg";
import move from "../../assets/images/move.png";
import useSound from "use-sound";
import clickSound from "../../assets/audio/click.wav";
import { useStore } from "../../store";
import ArrowMovement from "../atoms/ArrowMovement";

export const transition = {
  duration: 0.7,
  ease: [0.82, -0.05, 0.21, 1.03],
};

const GameControls = ({
  type,
  setShowControls,
  onClick,
  onPointerUp,
  onPointerDown,
  differentText,
  disable,
  started,
  setPlayerPosition,
  playerPosition,
}) => {
  const soundSettingClick = useStore((state) => state.soundSettingClick);
  const touchDevice = useStore((state) => state.touchDevice);
  const [click] = useSound(clickSound, { volume: soundSettingClick });

  const renderControls = () => {
    switch (type) {
      case "mouse":
        return (
          <Grid container justify="center">
            <img alt="mouse" height="150px" src={mouse} />
            <Text
              style={{
                textAlign: "center",
                marginBottom: "2em",
                marginTop: "2em",
              }}
              light
            >
              CLICK TO RECREATE THE CORRECT PATH
            </Text>
          </Grid>
        );
      case "button":
        return (
          <RedButton
            disable={disable}
            differentText={differentText}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onClick={onClick}
            click={click}
          />
        );
      case "input":
        return (
          <Text
            style={{
              marginBottom: "3em",
              textAlign: "center",
              lineHeight: 1.3,
            }}
            light
          >
            FILL IN THE CORRECT NUMBER
          </Text>
        );
      case "arrow-buttons":
        return touchDevice ? (
          <ArrowMovement
            started={started}
            setPlayerPosition={setPlayerPosition}
            playerPosition={playerPosition}
            speed={8}
          />
        ) : (
          <Text
            style={{
              marginBottom: "3em",
              textAlign: "center",
              lineHeight: 1.3,
            }}
            light
          >
            <img alt="move" height="200px" src={move} />
          </Text>
        );
      default:
        break;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.5 } }}
      exit={{ opacity: 0 }}
      style={{ height: "100%" }}
    >
      <Grid container style={{ height: "100%" }} justify="center">
        <Grid item xs={10}>
          <Grid
            style={{ height: "100%" }}
            container
            direction="column"
            alignItems="center"
            justify="space-between"
          >
            <Grid style={{ marginTop: "1em", width: "100%" }} item>
              <Grid
                container
                alignItems="center"
                direction="row"
                justify="space-between"
              >
                <Grid item>
                  <BigHeader
                    fontSize="calc(0.4rem + 2vw)"
                    style={{
                      fontFamily: "cube",
                      userSelect: "none",
                      // marginRight: 40,
                      y: 5,
                    }}
                    light
                  >
                    CONTROLS
                  </BigHeader>
                </Grid>
                <Grid item>
                  <Button
                    padding="12px 15px"
                    layoutId="button"
                    onClick={() => setShowControls(false)}
                  >
                    <ButtonText fontSize="calc(0.5rem + 0.3vw)">
                      BACK
                    </ButtonText>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>{renderControls()}</Grid>
              </Grid>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default GameControls;
