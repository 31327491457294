import React, { useEffect, useRef, useState } from "react";

import { motion, useAnimation } from "framer-motion";
import { theme } from "../../theme";
import { useAvoid } from "../../store/games/avoid";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const EndBox = ({ player, started, controls, resultRef }) => {
  const ref = useRef();
  const { setResultAvoid } = useAvoid();

  function collisionDetection() {
    if (ref.current && player.current) {
      const rect1 = ref.current.getBoundingClientRect();
      const rect2 = player.current.getBoundingClientRect();
      if (
        rect1.x < rect2.x + rect2.width &&
        rect1.x + rect1.width > rect2.x &&
        rect1.y < rect2.y + rect2.height &&
        rect1.y + rect1.height > rect2.y
      ) {
        setResultAvoid(true);
        resultRef.current = true;
        controls.stop("move");
      } else {
        requestAnimationFrame(collisionDetection);
      }
    }
  }

  useEffect(() => {
    if (started) {
      let animation = requestAnimationFrame(collisionDetection);
      return () => cancelAnimationFrame(animation);
    }
  }, [started]);

  return (
    <div
      ref={ref}
      style={{
        width: "6%",
        height: "1%",
        background: "red",
        visibility: "hidden",
        position: "absolute",
        top: -8,
        left: 0,
        right: 0,
        margin: "auto",
      }}
    />
  );
};

const Bar = ({ i, player, controls, started, resultRef, simplified }) => {
  const { setResultAvoid, result } = useAvoid();
  const controlsBar = useAnimation();
  const ref = useRef();
  const { small } = useWindowDimensions();

  const variants = {
    left: { width: ["0%", "60%", "0%"] },
    right: { width: ["60%", "0%", "60%"] },
  };

  useEffect(() => {
    if (started) {
      if (i === 1 || i === 3 || i === 5) {
        controlsBar.start("left");
      } else {
        controlsBar.start("right");
      }
    }
  }, [i, started, simplified]);

  useEffect(() => {
    if (result !== undefined) {
      controlsBar.stop("left");
      controlsBar.stop("right");
    }
  }, [result]);

  const getY = () => {
    if (i === 0 || i === 1) {
      return small ? "25px" : "75px";
    } else if (i === 2 || i === 3) {
      return small ? "90px" : "200px";
    } else {
      return small ? "155px" : "325px";
    }
  };

  const getX = () => {
    if (i === 1 || i === 3 || i === 5) {
      return 0;
    } else {
      return "";
    }
  };

  function collisionDetection() {
    if (ref.current && player.current && resultRef.current === undefined) {
      const rect1 = ref.current.getBoundingClientRect();
      const rect2 = player.current.getBoundingClientRect();
      if (
        rect1.x < rect2.x + rect2.width &&
        rect1.x + rect1.width > rect2.x &&
        rect1.y < rect2.y + rect2.height &&
        rect1.y + rect1.height > rect2.y
      ) {
        setResultAvoid(false);
        resultRef.current = false;
        controls.stop("move");
      } else {
        requestAnimationFrame(collisionDetection);
      }
    }
  }

  useEffect(() => {
    if (started) {
      let animation = requestAnimationFrame(collisionDetection);
      return () => cancelAnimationFrame(animation);
    }
  }, [started, result]);

  return (
    <motion.div
      ref={ref}
      variants={variants}
      animate={controlsBar}
      transition={{
        duration: simplified ? 3.5 : 2.8,
        repeat: Infinity,
      }}
      style={{
        position: "absolute",
        right: getX(),
        y: getY(),
        height: small ? 25 : 50,
        backgroundColor: theme.colors.primary,
      }}
    />
  );
};

const PlayerBox = React.forwardRef((props, ref) => {
  const { small } = useWindowDimensions();
  const variants = {
    move: {
      y: small ? -3000 : -5000,
    },
    reset: {
      transition: { duration: 0.1 },
      y: 0,
    },
  };

  return (
    <motion.div
      ref={ref}
      transition={{ duration: 20, ease: [0.32, 0.36, 0.57, 0.53] }}
      animate={props.controls}
      variants={variants}
      style={{
        width: small ? "8%" : "6%",
        height: small ? "8%" : "6%",
        background: "blue",
        position: "absolute",
        bottom: "10%",
        left: 0,
        y: props.playerY,
        right: 0,
        margin: "auto",
      }}
    />
  );
});

const GridcellAvoid = ({
  resultFeedback,
  started,
  resultRef,
  simplified,
  controls,
}) => {
  const [bars] = useState([1, 2, 3, 4, 5, 6]);
  const playerRef = useRef();
  const { result } = useAvoid();

  useEffect(() => {
    controls.start("reset");
  }, [started]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: theme.colors.gradient,
      }}
    >
      {bars.map((bar, i) => (
        <Bar
          simplified={simplified}
          resultRef={resultRef}
          started={started}
          controls={controls}
          player={playerRef}
          key={i}
          i={i}
        />
      ))}
      <EndBox
        resultRef={resultRef}
        started={started}
        controls={controls}
        player={playerRef}
      />
      {started && <PlayerBox controls={controls} ref={playerRef} />}
      {resultFeedback !== undefined && (
        <video
          autoPlay
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            zIndex: 5,
          }}
          loop
          muted
          playsInline
        >
          <source
            src={`/${resultFeedback ? "win" : "lose"}.mp4`}
            type="video/mp4"
          />
        </video>
      )}
    </div>
  );
};

export default GridcellAvoid;
