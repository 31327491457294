import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { theme } from "../../theme";
import { usePerimeter } from "../../store/games/perimeter";
import { getRandomArbitrary } from "../../gameFunctions/calculate";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const Box = styled.div`
  width: ${(props) => (props.small ? "14.285%" : "10% ")};
  height: ${(props) => (props.small ? "14.285%" : "10% ")};
  position: relative;
  background: rgb(190, 223, 255);
  background: radial-gradient(
    circle,
    rgba(190, 223, 255, 1) 0%,
    rgba(223, 240, 255, 1) 68%,
    rgba(255, 255, 255, 1) 100%
  );
  float: left;
  border: 2px solid #64a4d3;
  display: flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #2a1a25;
  box-sizing: border-box;
`;

export const Perimeter = styled(motion.div)`
  position: absolute;
  width: ${(props) => (props.small ? "15%" : "10% ")};
  height: ${(props) => (props.small ? "15%" : "10% ")};
  border: ${(props) => `${props.theme.colors.secondary} 5px solid`};
  box-sizing: border-box;
  z-index: 5;
`;

export const InputBox = styled(motion.div)`
  position: absolute;
  margin-left: 12px;
  margin-top: 12px;
  width: ${(props) => (props.small ? "9%" : "6.5% ")};
  height: ${(props) => (props.small ? "9%" : "6.5% ")};
  border: 0 none;
  background-color: ${(props) => props.theme.colors.primary};
  z-index: 2;
`;

export const BigBox = styled(motion.div)`
  position: absolute;
  top: ${(props) => (props.small ? "15%" : "10.25% ")};
  left: ${(props) => (props.small ? "15%" : "10.25% ")};
  width: ${(props) => (props.small ? "70%" : "79.5% ")};
  height: ${(props) => (props.small ? "70%" : "79.5% ")};
  z-index: 1;
`;

export const BigBoxComponent = ({ small }) => {
  return <BigBox small={small} style={{ background: theme.colors.gradient }} />;
};

export const GridcellPerimeter = ({ small }) => {
  return <Box small={small} />;
};

export const GridPerimeter = React.forwardRef((props, ref) => {
  const [arr] = useState(["top", "bottom", "right", "left"]);
  const [type] = useState(arr[Math.floor(Math.random() * arr.length)]);
  const [position] = useState(
    !props.small ? `${getRandomArbitrary(2, 8)}0%` : `42.5%`
  );

  let type2;
  switch (type) {
    case "top":
    case "bottom":
      type2 = "right";
      break;
    case "right":
    case "left":
      type2 = "top";
      break;
    default:
      break;
  }

  const getPosition = (check) => {
    if (type === check) return 0;
    else if (type2 === check) return position;
  };

  return (
    <Perimeter
      small={props.small}
      ref={ref}
      style={{
        top: getPosition("top"),
        bottom: getPosition("bottom"),
        right: getPosition("right"),
        left: getPosition("left"),
      }}
    />
  );
});

export const GridcellPerimeterInput = React.forwardRef((props, ref) => {
  const { setStopped } = usePerimeter();
  const { small } = useWindowDimensions();

  const offset = props.small
    ? props.gameskeleton.offsetWidth -
      props.gameskeleton.offsetWidth * 0.08 -
      51
    : props.gameskeleton.offsetWidth -
      props.gameskeleton.offsetWidth * 0.08 -
      26;

  const variants = {
    keyframes: {
      top: small
        ? [-5, -5, offset + 10, offset + 10, -5]
        : [0, 0, offset - 5, offset - 5, 0],
      right: small
        ? [6, offset + 20, offset + 20, 8, 8]
        : [6, offset + 5, offset + 5, 8, 8],
    },
  };

  useEffect(() => {
    props.started && props.controls.start("keyframes");
  }, [props.started, props.controls]);

  useEffect(() => {
    if (props.started) {
      props.controls.start("keyframes");
    }
  }, [props.simplified, props.controls, props.started]);

  document.body.onkeyup = function (e) {
    if (e.keyCode === 32) {
      props.controls.stop();
      setStopped(true);
    }
  };

  return (
    <InputBox
      small={props.small}
      ref={ref}
      variants={variants}
      animate={props.controls}
      transition={{
        duration: props.simplified ? 4 : props.config.speed,
        loop: Infinity,
        ease: [0, 0, 0, 0],
      }}
    />
  );
});
