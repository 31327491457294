import React, { useEffect, useState } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import useSound from "use-sound";
import _ from "lodash";

import { Container } from "../../atoms/Container";
import GameSkeleton from "../../atoms/GameSkeleton";
import GridcellPathfinder from "../../gamecomponents/GridcellPathfinder";
import GameInterface from "../../organisms/GameInterface";
import gameMusic from "../../../assets/audio/game.mp3";
import { theme } from "../../../theme";
import { transition } from "../../organisms/GameControls";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useStore } from "../../../store";
import { usePathfinder } from "../../../store/games/pathfinder";
import { getRandomArbitrary } from "../../../gameFunctions/calculate";
import CountDown from "../../atoms/CountDown";
import GameControls from "../../organisms/GameControls";
import MobileGameControls from "../../organisms/MobileGameControls";
import BackButton from "../../atoms/BackButton";

const Pathfinder = () => {
  const { games, setSelectedGame, selectedGame, soundSettingGame } = useStore();
  const { xs } = useWindowDimensions();
  const history = useHistory();
  const {
    result,
    setResultPathfinder,
    differentPaths,
    config,
  } = usePathfinder();
  const [pathwayHint, setPathwayHint] = useState([]);
  const [show, setShow] = useState(false);
  const [simplified, setSimplified] = useState(false);
  const [started, setStarted] = useState(false);
  const [currentTile, setCurrentTile] = useState(0);
  const [retry, setRetry] = useState(false);
  const [random] = useState(getRandomArbitrary(0, 14));
  const [startCountdown, setStartCountdown] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [simplifyRetries, setSimplifyRetries] = useState(2);
  const selectedPath = differentPaths[random];
  const [playGameMusic, { stop }] = useSound(gameMusic, {
    volume: soundSettingGame,
  });
  const [clickable, setClickable] = useState(true);

  useEffect(() => {
    startCountdown && setClickable(false);
  }, [startCountdown]);

  useEffect(() => {
    !started && setClickable(true);
  }, [started]);

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  const simplify = () => {
    if (!show) {
      if (!simplified) {
        setSimplified(true);
        setPathwayHint(selectedPath.hints);
      }
    }
  };

  const startGame = () => {
    playGameMusic();
    setStarted(true);
    setResultPathfinder(undefined);
    setCurrentTile(selectedPath.path[0]);
  };

  const [open, setOpen] = useState(true);

  useEffect(() => {
    !xs && setOpen(true);
    if (startCountdown && result === undefined && xs) setOpen(false);
    if (result !== undefined && xs) setOpen(false);
  }, [xs, setOpen, startCountdown, result]);

  useEffect(() => {
    setSelectedGame(
      games.find((game) => game.link === history.location.pathname)
    );
    document.body.style.overflowX = "hidden";
    document.body.style.overflowy = "hidden";
  }, []);

  useEffect(() => {
    if (result !== undefined) setShowControls(false);
  }, [result]);

  return (
    <Container center fluid>
      <BackButton />
      <Grid container alignItems="center">
        <Grid
          style={{
            height: xs ? "auto" : "100vh",
          }}
          item
          xs={12}
          md={8}
        >
          <GameSkeleton started={started}>
            <CountDown
              startGame={startGame}
              setStartCountdown={setStartCountdown}
              startCountdown={startCountdown}
              setResult={setResultPathfinder}
            />
            {Array.from(Array(25).keys()).map((i) => (
              <GridcellPathfinder
                simplified={simplified}
                setSimplifyRetries={setSimplifyRetries}
                simplifyRetries={simplifyRetries}
                stopGameMusic={stop}
                config={config}
                startCountdown={startCountdown}
                pathDirections={selectedPath.pathDirections}
                inPath={selectedPath.path.indexOf(i)}
                path={selectedPath.path}
                key={i}
                currentTile={currentTile}
                setCurrentTile={setCurrentTile}
                started={started}
                index={i}
                result={result}
                setStarted={setStarted}
                pathwayHint={pathwayHint}
                setResult={setResultPathfinder}
                setShow={setShow}
                show={show}
                retry={retry}
              />
            ))}
            {result !== undefined && (
              <video
                autoPlay
                style={{
                  width: "100%",
                  height: "auto",
                  position: "absolute",
                  top: 0,
                  zIndex: 5,
                }}
                loop
                muted
                playsInline
              >
                <source
                  src={`/${result ? "win" : "lose"}.mp4`}
                  type="video/mp4"
                />
              </video>
            )}
          </GameSkeleton>
        </Grid>
        <Grid item xs={4} />
        <motion.div
          transition={transition}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: xs ? 20 : 1,
            overflowY: "auto",
            height: "100vh",
            height: "-webkit-fill-available",
          }}
          exit={{ x: 0, transition: { delay: 0.5 } }}
          initial={{
            x: 0,
            width: xs ? (open ? "100vw " : "75px") : "34vw",
            height: "-webkit-fill-available",
            height: xs ? (open ? "100vh" : "40px") : "100vh",
            backgroundColor: xs
              ? open
                ? theme.colors.primary
                : theme.colors.secondary
              : theme.colors.primary,
            boxShadow: `0 5px 0 ${theme.colors.secondaryDarker}`,
          }}
          animate={{
            x: xs ? (open ? 0 : -20) : 0,
            y: xs ? (open ? 0 : 20) : 0,
            height: xs ? (open ? "100vh" : "40px") : "100vh",
            height: xs ? (open ? "-webkit-fill-available" : "40px") : "100vh",
            width: xs ? (open ? "100vw " : "75px") : "34vw",
            backgroundColor: xs
              ? open
                ? theme.colors.primary
                : theme.colors.secondary
              : theme.colors.primary,
            boxShadow: `0 5px 0 ${theme.colors.secondaryDarker}`,
          }}
        >
          <AnimatePresence exitBeforeEnter>
            {showControls && !xs ? (
              <GameControls
                simplified={simplified}
                simplify={simplify}
                type={selectedGame?.type}
                video={selectedGame?.video}
                setShowControls={setShowControls}
              />
            ) : (
              <GameInterface
                setShowControls={setShowControls}
                started={started}
                result={result}
                startGame={() => {
                  setShowControls(true);
                  setStartCountdown(true);
                  if (result === false) {
                    if (!simplified) {
                      setRetry(true);
                    }
                    if (simplifyRetries === 0) setRetry(true);
                  }
                }}
                simplify={simplify}
                setOpen={setOpen}
                open={open}
                simplified={simplified}
              />
            )}
          </AnimatePresence>
        </motion.div>
        {xs && (
          <MobileGameControls
            clickable={clickable}
            startGame={() => {
              setShowControls(true);
              setStartCountdown(true);
              if (result === false) setRetry(true);
            }}
            simplify={() => simplify()}
            started={started}
            result={result}
            startCountdown={startCountdown}
            simplified={simplified}
            type={selectedGame?.type}
            setSimplify={setSimplified}
            simplified={simplified}
          />
        )}
      </Grid>
    </Container>
  );
};

export default Pathfinder;
