import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { transition } from "../organisms/GameControls";
import { useStore } from "../../store";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const GameSkeletonBox = styled(motion.div)`
  width: 75vh;
  height: 75vh;
  display: flex;
  margin-top: 1em;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @media screen and (orientation: portrait) {
    width: 300px;
    height: 300px;
  }
`;

const GameSkeleton = React.forwardRef((props, ref) => {
  const { small } = useWindowDimensions();
  return (
    <GameSkeletonBox
      transition={transition}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, border: "10px #720c0f solid" }}
      ref={ref}
      style={{
        background: props.color,
        marginTop: small ? "0em" : "5em",
        y: small ? -50 : 0,
      }}
    >
      {props.children}
    </GameSkeletonBox>
  );
});

export default GameSkeleton;
