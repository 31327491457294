import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { useHistory } from "react-router-dom";
import useSound from "use-sound";
import { motion } from "framer-motion";

import logo from "../../assets/images/logo.png";
import controls from "../../assets/images/gamepad.svg";
import { ButtonText, Text } from "../atoms/typography";
import { Button } from "../atoms/Button";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import clickSound from "../../assets/audio/click.wav";
import { useStore } from "../../store";

const InterfaceHeader = ({ setOpen, setShowControls }) => {
  const history = useHistory();
  const soundSettingClick = useStore((state) => state.soundSettingClick);
  const [click] = useSound(clickSound, { volume: soundSettingClick });

  const { xs, md, lg } = useWindowDimensions();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.5 } }}
    >
      <Grid
        container
        style={{ textAlign: "center" }}
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          <Button
            layoutId="button"
            small
            exit={{ opacity: 0 }}
            fullWidth
            onTapStart={() => click()}
            onClick={() => history.push("/")}
          >
            <ButtonText fontSize="calc(0.5rem + 0.3vw)">BACK</ButtonText>
          </Button>
        </Grid>
        <Grid item>
          <img src={logo} alt="logo" width={xs || md || lg ? "100" : "200"} />
        </Grid>
        <Grid onClick={() => xs && setOpen(false)} item>
          {xs ? (
            <Button padding="5px 20px" onClick={() => setOpen(false)}>
              <Text style={{ fontFamily: "blair", margin: 0 }}>x</Text>
            </Button>
          ) : (
            <Button
              style={{ padding: "0em 1.5em" }}
              onClick={() => setShowControls(true)}
            >
              <img width={40} src={controls} alt="controls" />
            </Button>
          )}
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default InterfaceHeader;
