import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import { Text } from "../atoms/typography";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const InterfaceControls = ({ selectedGame }) => {
  const { width, small } = useWindowDimensions();
  const xs = width < 1700;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.5 } }}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Text fontSize="1rem" style={{ letterSpacing: 2 }} light>
            LIVES LOST IF
          </Text>
          <Text
            fontFamily="graphik"
            fontSize="calc(1rem + 0.2vw)"
            style={{ lineHeight: 1.5 }}
            light
          >
            {selectedGame?.controls}
          </Text>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default InterfaceControls;
