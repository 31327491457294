import create from "zustand";

export const useCircumvent = create((set) => ({
  result: undefined,
  setResultCircumvent: (data) => set({ result: data }),
  obstacles: [
    [
      { x: "4vh", y: "12vh" },
      { x: "20vh", y: "8vh" },
      { x: "-9.5vh", y: "8vh" },
      { x: "-20vh", y: "7vh" },
      { x: "-3vh", y: "25vh" },
      { x: "5vh", y: "22vh" },
      { x: "-19vh", y: "22vh" },
      { x: "13vh", y: "30vh" },
      { x: "0vh", y: "38vh" },
      { x: "-15vh", y: "36vh" },
      { x: "20vh", y: "43vh" },
      { x: "-16vh", y: "43vh" },
    ],
    [
      { x: "8vh", y: "14vh" },
      { x: "20vh", y: "20vh" },
      { x: "-9.5vh", y: "8vh" },
      { x: "-20vh", y: "10vh" },
      { x: "-3vh", y: "20vh" },
      { x: "5vh", y: "22vh" },
      { x: "-19vh", y: "22vh" },
      { x: "13vh", y: "30vh" },
      { x: "0vh", y: "38vh" },
      { x: "-14vh", y: "36vh" },
      { x: "10vh", y: "43vh" },
      { x: "-16vh", y: "43vh" },
    ],
    [
      { x: "17vh", y: "14vh" },
      { x: "20vh", y: "20vh" },
      { x: "-9.5vh", y: "8vh" },
      { x: "-20vh", y: "10vh" },
      { x: "-3vh", y: "20vh" },
      { x: "5vh", y: "22vh" },
      { x: "-19vh", y: "22vh" },
      { x: "-12vh", y: "20vh" },
      { x: "3vh", y: "38vh" },
      { x: "-5vh", y: "36vh" },
      { x: "10vh", y: "43vh" },
      { x: "-18vh", y: "43vh" },
      { x: "18vh", y: "50vh" },
    ],
    [
      { x: "17vh", y: "14vh" },
      { x: "20vh", y: "20vh" },
      { x: "-9.5vh", y: "8vh" },
      { x: "7vh", y: "9vh" },
      { x: "-4vh", y: "21vh" },
      { x: "4vh", y: "23vh" },
      { x: "16vh", y: "28vh" },
      { x: "-12vh", y: "20vh" },
      { x: "3vh", y: "38vh" },
      { x: "-5vh", y: "36vh" },
      { x: "10vh", y: "43vh" },
      { x: "-18vh", y: "43vh" },
      { x: "18vh", y: "50vh" },
      { x: "-20vh", y: "30vh" },
    ],
  ],
  obstaclesMobile: [
    [
      { x: "40px", y: "50px" },
      { x: "20px", y: "80px" },
      { x: "-90px", y: "70px" },
      { x: "-20px", y: "70px" },
      { x: "-30px", y: "120px" },
      { x: "10px", y: "150px" },
      { x: "-120px", y: "130px" },
      { x: "100px", y: "180px" },
      { x: "80px", y: "130px" },
      { x: "-50px", y: "150px" },
      { x: "-60px", y: "20px" },
    ],
    [
      { x: "40px", y: "50px" },
      { x: "20px", y: "80px" },
      { x: "-90px", y: "70px" },
      { x: "-20px", y: "70px" },
      { x: "-30px", y: "120px" },
      { x: "10px", y: "150px" },
      { x: "-120px", y: "130px" },
      { x: "100px", y: "180px" },
      { x: "80px", y: "130px" },
      { x: "-50px", y: "150px" },
      { x: "-60px", y: "20px" },
    ],
  ],
}));
