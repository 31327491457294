import { motion } from "framer-motion";
import React, { useState } from "react";

import { Text } from "./typography";

const RedButton = ({
  click,
  onClick,
  small,
  differentText,
  onPointerDown,
  onPointerUp,
  disable = false,
}) => {
  const [hovered, set] = useState(false);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: small ? "95px" : "17vw",
        width: small ? "95px" : "17vw",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 7px 0 #C9C9C9",
        cursor: "pointer",
        marginBottom: small ? "7px" : "2em",
          userSelect: "none",
      }}
    >
      <motion.div
        onTapStart={() => (!disable ? click() : undefined)}
        onClick={!disable ? onClick : undefined}
        onPointerUp={onPointerUp}
        onPointerDown={onPointerDown}
        onMouseDown={() => set(true)}
        onMouseUp={() => set(false)}
        style={{
          marginTop: small ? "7px" : 0,
          width: small ? "75px" : "14vw",
          height: small ? "70px" : "14vw",
          background: "rgb(255,255,255)",
          background: disable
            ? "rgba(0,0,0,0.2)"
            : "linear-gradient(149deg, rgba(240,150,150,1) 0%, rgba(193,55,58,1) 23%, rgba(174,31,35,1) 39%, rgba(174,31,35,1) 100%)",
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          y: !hovered ? -7 : 0,
          boxShadow: !hovered
            ? disable
              ? "0 7px 0 rgba(0,0,0,0.4)"
              : "0 7px 0 #7A1518"
            : "0 0px 0 #7A1518",
        }}
      >
        <Text
          style={{ scale: hovered ? 0.97 : 1, userSelect: "none" }}
          fontSize={small ? "1re," : "2rem"}
          light
        >
          {differentText ? differentText : "STOP"}
        </Text>
      </motion.div>
    </motion.div>
  );
};

export default RedButton;

// import { motion } from "framer-motion";
// import React, { useState } from "react";
// import redbutton from "../../assets/images/red-button.png";
// import { theme } from "../../theme";
// import { Text } from "./typography";

// const RedButton = ({
//   click,
//   onClick,
//   small,
//   style,
//   onPointerDown,
//   onPointerUp,
// }) => {
//   const [hovered, set] = useState(false);

//   return (
//     <div
//       style={{
//         height: small ? "120px" : "17vw",
//         width: small ? "120px" : "17vw",
//         backgroundImage: `url(${redbutton})`,
//         backgroundSize: "cover",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         cursor: "pointer",
//         marginBottom: "4em",
//         ...style,
//       }}
//     >
//       <motion.div
//         onTapStart={() => click()}
//         onClick={onClick}
//         onPointerUp={onPointerUp}
//         onPointerDown={onPointerDown}
//         onMouseDown={() => set(true)}
//         onMouseUp={() => set(false)}
//         whileTap={{
//           width: small ? "77px" : "11.5vw",
//           height: small ? "72px" : "10.5vw",
//         }}
//         style={{
//           width: small ? "75px" : "11vw",
//           height: small ? "70px" : "10vw",
//           backgroundColor: "#982326",
//           position: "absolute",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <Text
//           animate={{ scale: hovered ? 0.95 : 1, userSelect: "none" }}
//           fontSize={small ? "1re," : "2rem"}
//           light
//         >
//           STOP
//         </Text>
//       </motion.div>
//     </div>
//   );
// };

// export default RedButton;
