import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";

import { theme } from "../../theme";
import portrait from "../../assets/images/portrait.png";
import { BigHeader } from "../../components/atoms/typography";

const TurnDevice = ({ mode }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.colors.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container justify="center" alignItems="center">
        <Grid
          style={{ textAlign: "center", marginBottom: "20px" }}
          item
          xs={12}
        >
          <img
            style={{
              marginBottom: "2em",
              transform: mode !== "portrait" ? "rotate(90deg)" : "rotate(0deg)",
            }}
            alt="portrait"
            src={portrait}
            width={200}
            height={150}
          />
        </Grid>
        <Grid item>
          <BigHeader light fontSize="1em">
            {mode === "portrait"
              ? "TURN YOUR DEVICE TO PORTRAIT MODE"
              : "TURN YOUR DEVICE TO LANDSCAPE MODE"}
          </BigHeader>
        </Grid>
      </Grid>
    </div>
  );
};

export default TurnDevice;
