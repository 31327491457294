import React from "react";
import { useHistory } from "react-router";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Button } from "./Button";
import { ButtonText } from "./typography";

const BackButton = () => {
  const { xs } = useWindowDimensions();
  const history = useHistory();
  return (
    xs && (
      <div
        onClick={() => history.push("/")}
        style={{ position: "absolute", top: 20, left: 20 }}
      >
        <Button style={{ padding: "0.65em 0.5em" }}>
          <ButtonText
            style={{ fontFamily: "blair", margin: 0 }}
            fontSize="0.8em"
            light
          >
            BACK
          </ButtonText>
        </Button>
      </div>
    )
  );
};

export default BackButton;
