import React, { useEffect, useRef, useState } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import useSound from "use-sound";

import { Container } from "../../atoms/Container";
import GameSkeleton from "../../atoms/GameSkeleton";
import GridcellRepetition from "../../gamecomponents/GridcellRepetition";
import GameInterface from "../../organisms/GameInterface";
import { theme } from "../../../theme";
import { transition } from "../../organisms/GameControls";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useStore } from "../../../store";
import { useRepetition } from "../../../store/games/repetition";
import CountDown from "../../atoms/CountDown";
import GameControls from "../../organisms/GameControls";
import MobileGameControls from "../../organisms/MobileGameControls";
import gameMusic from "../../../assets/audio/game.mp3";
import winSound from "../../../assets/audio/win.wav";
import failSound from "../../../assets/audio/fail.wav";
import BackButton from "../../atoms/BackButton";

const Repetition = () => {
  const {
    games,
    setSelectedGame,
    selectedGame,
    soundSettingGame,
    soundSettingWin,
    soundSettingFail,
  } = useStore();
  const { result, setResultRepetition } = useRepetition();
  const { xs } = useWindowDimensions();
  const history = useHistory();
  const [simplified, setSimplified] = useState(false);
  const [started, setStarted] = useState(false);
  const [resultFeedback, setResultFeedback] = useState(undefined);
  const [activeTile, setActiveTile] = useState();
  const [shownTiles, setShownTiles] = useState([]);
  let clickedRef = useRef(false);
  let resultRef = useRef();
  const [startCountdown, setStartCountdown] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [startChecking, setStartChecking] = useState(false);
  const [playGameMusic, { stop }] = useSound(gameMusic, {
    volume: soundSettingGame,
  });
  const [playWinMusic] = useSound(winSound, { volume: soundSettingWin });
  const [playFailMusic] = useSound(failSound, { volume: soundSettingFail });
  const [clickable, setClickable] = useState(true);

  useEffect(() => {
    startCountdown && setClickable(false);
  }, [startCountdown]);

  useEffect(() => {
    !started && setClickable(true);
  }, [started]);

  const startGame = () => {
    playGameMusic();
    setStarted(true);
    setStartChecking(false);
    clickedRef.current = false;
    resultRef.current = undefined;
    setResultRepetition(undefined);
    setShownTiles([]);
    setActiveTile(undefined);
    setResultFeedback(undefined);
  };

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  useEffect(() => {
    if (result) {
      setStarted(false);
      setResultFeedback(true);
      setShowControls(false);
      setShownTiles([]);
      stop();
      playWinMusic();
    } else if (result === false) {
      setResultFeedback(false);
      setStarted(false);
      setShowControls(false);
      setShownTiles([]);
      stop();
      playFailMusic();
    }
  }, [result]);

  const [open, setOpen] = useState(true);
  useEffect(() => {
    !xs && setOpen(true);
    if (startCountdown && result === undefined && xs) setOpen(false);
    if (result !== undefined && xs) setOpen(false);
  }, [xs, setOpen, startCountdown, result]);

  useEffect(() => {
    setSelectedGame(
      games.find((game) => game.link === history.location.pathname)
    );
    document.body.style.overflowY = "hidden";
    document.body.style.overflowX = "hidden";
  }, []);

  return (
    <Container center fluid>
      <BackButton />
      <Grid container alignItems="center">
        <Grid
          style={{
            height: xs ? "auto" : "100vh",
          }}
          item
          xs={12}
          md={8}
        >
          <GameSkeleton started={started}>
            <CountDown
              startGame={startGame}
              setStartCountdown={setStartCountdown}
              startCountdown={startCountdown}
              setResult={setResultRepetition}
            />
            <GridcellRepetition
              startChecking={startChecking}
              setStartChecking={setStartChecking}
              clickedRef={clickedRef}
              resultRef={resultRef}
              result={result}
              activeTile={activeTile}
              setActiveTile={setActiveTile}
              shownTiles={shownTiles}
              setShownTiles={setShownTiles}
              started={started}
              resultFeedback={resultFeedback}
              simplified={simplified}
            />
          </GameSkeleton>
        </Grid>
        <Grid item xs={4} />
        <motion.div
          transition={transition}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: xs ? 20 : 1,
            overflowY: "auto",
            height: "100vh",
            height: "-webkit-fill-available",
          }}
          exit={{ x: 0, transition: { delay: 0.5 } }}
          initial={{
            x: 0,
            width: xs ? (open ? "100vw " : "75px") : "34vw",
            height: "-webkit-fill-available",
            height: xs ? (open ? "100vh" : "40px") : "100vh",
            backgroundColor: xs
              ? open
                ? theme.colors.primary
                : theme.colors.secondary
              : theme.colors.primary,
            boxShadow: `0 5px 0 ${theme.colors.secondaryDarker}`,
          }}
          animate={{
            x: xs ? (open ? 0 : -20) : 0,
            y: xs ? (open ? 0 : 20) : 0,
            height: xs ? (open ? "100vh" : "40px") : "100vh",
            height: xs ? (open ? "-webkit-fill-available" : "40px") : "100vh",
            width: xs ? (open ? "100vw " : "75px") : "34vw",
            backgroundColor: xs
              ? open
                ? theme.colors.primary
                : theme.colors.secondary
              : theme.colors.primary,
            boxShadow: `0 5px 0 ${theme.colors.secondaryDarker}`,
          }}
        >
          <AnimatePresence exitBeforeEnter>
            {showControls && !xs ? (
              <GameControls
                simplified={simplified}
                simplify={() => setSimplified(true)}
                type={selectedGame?.type}
                setShowControls={setShowControls}
                onClick={() => {
                  clickedRef.current = true;
                }}
              />
            ) : (
              <GameInterface
                setShowControls={setShowControls}
                started={started}
                result={result}
                startGame={() => {
                  setResultRepetition(undefined);
                  setResultFeedback(undefined);
                  setShowControls(true);
                  setStartCountdown(true);
                }}
                simplify={() => setSimplified(true)}
                setOpen={setOpen}
                open={open}
                simplified={simplified}
              />
            )}
          </AnimatePresence>
        </motion.div>
        {xs && (
          <MobileGameControls
            clickable={clickable}
            onClick={() => (clickedRef.current = true)}
            simplify={() => setSimplified(true)}
            simplified={simplified}
            startGame={() => {
              setResultRepetition(undefined);
              setResultFeedback(undefined);
              setShowControls(true);
              setStartCountdown(true);
            }}
            started={started}
            result={result}
            type={selectedGame?.type}
          />
        )}
      </Grid>
    </Container>
  );
};

export default Repetition;
