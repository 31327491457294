import Grid from "@bit/mui-org.material-ui.grid";
import React from "react";
import styled from "styled-components";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Button } from "./Button";
import { ButtonText } from "./typography";

const NumberWrapper = styled.div`
  height: ${(props) => (props.small ? "100px" : "120px")};
  width: ${(props) => (props.small ? "40px" : "60px")};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
`;

const GameNumberInput = ({
  number1,
  number2,
  setNumber1,
  setNumber2,
  enter,
}) => {
  const { small } = useWindowDimensions();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      spacing={1}
      direction="row"
    >
      <Grid item>
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <Button
              onClick={() => number1 < 9 && setNumber1(number1 + 1)}
              fullWidth
              style={{ marginBottom: 2 }}
              padding="0px 10px"
            >
              <ButtonText
                style={{
                  fontSize: small ? "1.2em" : "1.5em",
                  margin: 0,
                  fontFamily: "the-cube",
                }}
              >
                +
              </ButtonText>
            </Button>
          </Grid>
          <Grid item>
            <NumberWrapper small={small}>
              <span style={{ color: "#fff", fontSize: small ? "2em" : "3em" }}>
                {number1}
              </span>
            </NumberWrapper>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => number1 > 0 && setNumber1(number1 - 1)}
              fullWidth
              padding="0px 10px"
            >
              <ButtonText
                style={{
                  fontSize: small ? "1.2em" : "1.5em",
                  margin: 0,
                  fontFamily: "the-cube",
                }}
              >
                -
              </ButtonText>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <Button
              onClick={() => number2 < 9 && setNumber2(number2 + 1)}
              fullWidth
              style={{ marginBottom: 2 }}
              padding="0px 10px"
            >
              <ButtonText
                style={{
                  fontSize: small ? "1.2em" : "1.5em",
                  margin: 0,
                  fontFamily: "the-cube",
                }}
              >
                +
              </ButtonText>
            </Button>
          </Grid>
          <Grid item>
            <NumberWrapper small={small}>
              <span style={{ color: "#fff", fontSize: small ? "2em" : "3em" }}>
                {number2}
              </span>
            </NumberWrapper>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => number2 > 0 && setNumber2(number2 - 1)}
              fullWidth
              padding="0px 10px"
            >
              <ButtonText
                style={{
                  fontSize: small ? "1.2em" : "1.5em",
                  margin: 0,
                  fontFamily: "the-cube",
                }}
              >
                -
              </ButtonText>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button padding={small ? "10px 10px" : "10px 20px"}>
          <ButtonText
            onClick={enter}
            style={{ fontSize: small ? "1em" : "1.5em", margin: 0 }}
          >
            ENTER
          </ButtonText>
        </Button>
      </Grid>
    </Grid>
  );
};

export default GameNumberInput;
