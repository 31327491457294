import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useHistory } from "react-router-dom";
import useSound from "use-sound";

import { Container } from "../../atoms/Container";
import GameSkeleton from "../../atoms/GameSkeleton";
import { transition } from "../../organisms/GameControls";
import { theme } from "../../../theme";
import GameInterface from "../../organisms/GameInterface";
import GridcellAvoid from "../../gamecomponents/GridcellAvoid";
import { useStore } from "../../../store";
import { useAvoid } from "../../../store/games/avoid";
import CountDown from "../../atoms/CountDown";
import GameControls from "../../organisms/GameControls";
import MobileGameControls from "../../organisms/MobileGameControls";
import gameMusic from "../../../assets/audio/game.mp3";
import winSound from "../../../assets/audio/win.wav";
import failSound from "../../../assets/audio/fail.wav";
import BackButton from "../../atoms/BackButton";

const Avoid = () => {
  const {
    games,
    setSelectedGame,
    selectedGame,
    soundSettingGame,
    soundSettingWin,
    soundSettingFail,
  } = useStore();
  const { result, setResultAvoid } = useAvoid();
  const [resultFeedback, setResultFeedback] = useState(undefined);
  const [started, setStarted] = useState(false);
  const [simplified, setSimplified] = useState(false);
  const [open, setOpen] = useState(true);
  const { xs } = useWindowDimensions();
  const history = useHistory();
  const resultRef = useRef();
  const controls = useAnimation();
  const [startCountdown, setStartCountdown] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [playGameMusic, { stop }] = useSound(gameMusic, {
    volume: soundSettingGame,
  });
  const [playWinMusic] = useSound(winSound, { volume: soundSettingWin });
  const [playFailMusic] = useSound(failSound, { volume: soundSettingFail });
  const [clickable, setClickable] = useState(true);

  useEffect(() => {
    startCountdown && setClickable(false);
  }, [startCountdown]);

  useEffect(() => {
    !started && setClickable(true);
  }, [started]);

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  const startGame = () => {
    setStarted(true);
    playGameMusic();
    setShowControls(true);
    setResultFeedback(undefined);
    setResultAvoid(undefined);
    resultRef.current = undefined;
  };

  useEffect(() => {
    if (result && resultFeedback === undefined) {
      setResultFeedback(true);
      setShowControls(false);
      playWinMusic();
      stop();
    } else if (result === false && resultFeedback === undefined) {
      setResultFeedback(false);
      setShowControls(false);
      playFailMusic();
      stop();
    }
    if (resultFeedback !== undefined) {
      setStarted(false);
    }
  }, [result, resultFeedback]);

  useEffect(() => {
    setSelectedGame(
      games.find((game) => game.link === history.location.pathname)
    );
    document.body.style.overflowY = "hidden";
    document.body.style.overflowX = "hidden";
  }, []);
  useEffect(() => {
    !xs && setOpen(true);
    if (startCountdown && result === undefined && xs) setOpen(false);
    if (result !== undefined && xs) setOpen(false);
  }, [xs, setOpen, startCountdown, result]);

  return (
    <Container center fluid>
      <BackButton />
      <Grid container alignItems="center">
        <Grid
          style={{
            height: xs ? "auto" : "100vh",
          }}
          item
          xs={12}
          md={8}
        >
          <GameSkeleton
            started={started}
            style={{
              marginTop: "5em",
            }}
          >
            <CountDown
              startGame={startGame}
              setStartCountdown={setStartCountdown}
              startCountdown={startCountdown}
              setResult={setResultAvoid}
            />
            <GridcellAvoid
              controls={controls}
              resultRef={resultRef}
              resultFeedback={resultFeedback}
              setResult={setResultAvoid}
              result={result}
              started={started}
              simplified={simplified}
              setStarted={setStarted}
              startGame={startGame}
            />
          </GameSkeleton>
        </Grid>
        <Grid item xs={4}>
          <motion.div
            transition={transition}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: xs ? 20 : 1,
              overflowY: "auto",
              height: "100vh",
              height: "-webkit-fill-available",
            }}
            exit={{ x: 0, transition: { delay: 0.5 } }}
            initial={{
              x: 0,
              width: xs ? (open ? "100vw " : "75px") : "34vw",
              height: "-webkit-fill-available",
              height: xs ? (open ? "100vh" : "40px") : "100vh",
              backgroundColor: xs
                ? open
                  ? theme.colors.primary
                  : theme.colors.secondary
                : theme.colors.primary,
              boxShadow: `0 5px 0 ${theme.colors.secondaryDarker}`,
            }}
            animate={{
              x: xs ? (open ? 0 : -20) : 0,
              y: xs ? (open ? 0 : 20) : 0,
              height: xs ? (open ? "100vh" : "40px") : "100vh",
              height: xs ? (open ? "-webkit-fill-available" : "40px") : "100vh",
              width: xs ? (open ? "100vw " : "75px") : "34vw",
              backgroundColor: xs
                ? open
                  ? theme.colors.primary
                  : theme.colors.secondary
                : theme.colors.primary,
              boxShadow: `0 5px 0 ${theme.colors.secondaryDarker}`,
            }}
          >
            <AnimatePresence exitBeforeEnter>
              {showControls && !xs ? (
                <GameControls
                  simplified={simplified}
                  simplify={() => setSimplified(true)}
                  type={selectedGame?.type}
                  differentText={selectedGame?.differentText}
                  setShowControls={setShowControls}
                  onPointerUp={() =>
                    result === undefined && started && controls.stop("move")
                  }
                  onPointerDown={() =>
                    result === undefined && started && controls.start("move")
                  }
                />
              ) : (
                <GameInterface
                  setShowControls={setShowControls}
                  started={started}
                  result={result}
                  startGame={() => {
                    setResultAvoid(undefined);
                    setResultFeedback(undefined);
                    setShowControls(true);
                    setStartCountdown(true);
                  }}
                  simplify={() => setSimplified(true)}
                  setOpen={setOpen}
                  open={open}
                  simplified={simplified}
                />
              )}
            </AnimatePresence>
          </motion.div>
        </Grid>
        {xs && (
          <MobileGameControls
            clickable={clickable}
            differentText={selectedGame?.differentText}
            onPointerUp={() =>
              result === undefined && started && controls.stop("move")
            }
            onPointerDown={() =>
              result === undefined && started && controls.start("move")
            }
            simplify={() => setSimplified(true)}
            simplified={simplified}
            startGame={() => {
              setResultAvoid(undefined);
              setResultFeedback(undefined);
              setShowControls(true);
              setStartCountdown(true);
            }}
            started={started}
            result={result}
            type={selectedGame?.type}
          />
        )}
      </Grid>
    </Container>
  );
};

export default Avoid;
