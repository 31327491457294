import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import useSound from "use-sound";

import { theme } from "../../theme";
import RedButton from "../atoms/RedButton";
import { Button } from "../atoms/Button";
import { ButtonText, Text } from "../atoms/typography";
import { useStore } from "../../store";
import clickSound from "../../assets/audio/click.wav";
import { AnimatePresence, motion } from "framer-motion";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ArrowMovement from "../atoms/ArrowMovement";

const MobileGameControls = ({
  onClick,
  differentText,
  type,
  started,
  simplified,
  result,
  startGame,
  simplify,
  disable,
  onPointerDown,
  onPointerUp,
  setPlayerPosition,
  playerPosition,
  speed,
  clickable,
}) => {
  const soundSettingClick = useStore((state) => state.soundSettingClick);
  const [click] = useSound(clickSound, { volume: soundSettingClick });
  const { small } = useWindowDimensions();

  const renderControls = () => {
    switch (type) {
      case "mouse":
        return (
          <Grid container justify="center">
            <Text style={{ marginBottom: "3em", textAlign: "center" }} light>
              TOUCH THE CORRECT PATHS
            </Text>
          </Grid>
        );
      case "button":
        return (
          <RedButton
            small={small}
            disable={disable}
            differentText={differentText}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onClick={onClick}
            click={click}
          />
        );
      case "input":
        return (
          <Text
            style={{
              textAlign: "center",
              lineHeight: 1.3,
            }}
            light
          >
            FILL IN THE CORRECT NUMBER
          </Text>
        );
      case "arrow-buttons":
        return (
          <ArrowMovement
            started={started}
            setPlayerPosition={setPlayerPosition}
            playerPosition={playerPosition}
            speed={speed}
          />
        );
      default:
        break;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        style={{
          background: theme.colors.primary,
          position: "absolute",
          bottom: 0,
          height: 175,
        }}
        container
        justify="center"
        alignItems="center"
      >
        <Grid container alignItems="center" justify="center">
          <AnimatePresence exitBeforeEnter>
            {!clickable ? (
              renderControls()
            ) : (
              <Grid
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                component={motion.div}
                key="start"
                container
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  <Button
                    style={{ marginRight: 10 }}
                    onTapStart={() => click()}
                    onClick={() => startGame()}
                  >
                    <ButtonText fontSize="calc(0.7rem + 0.3vw)">
                      {result === false ? "RETRY" : "START"}
                    </ButtonText>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    animate={{
                      background: simplified
                        ? "rgba(0,0,0,0)"
                        : theme.colors.secondary,
                    }}
                    onTapStart={() => click()}
                    onClick={() => simplify()}
                  >
                    <ButtonText fontSize="calc(0.7rem + 0.3vw)">
                      {simplified ? "SIMPLIFIED" : "SIMPLIFY"}
                    </ButtonText>
                  </Button>
                </Grid>
              </Grid>
            )}
          </AnimatePresence>
        </Grid>
      </Grid>
    </div>
  );
};

export default MobileGameControls;
