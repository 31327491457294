import pathfinder from "../assets/images/pathfinder.mp4";
import perimeter from "../assets/images/perimeter.mp4";
import verticalDrop from "../assets/images/vertical-drop.mp4";
import timeFreeze from "../assets/images/time-freeze.mp4";
import circumvent from "../assets/images/circumvent.mp4";
import identical from "../assets/images/identical.mp4";
import stopZone from "../assets/images/stop-zone.mp4";
import calculate from "../assets/images/calculate.mp4";
import replicate from "../assets/images/replicate.mp4";
import avoid from "../assets/images/avoid.mp4";
import dimension from "../assets/images/dimension.mp4";
import exact from "../assets/images/exact.mp4";
import timeSplit from "../assets/images/time-split.mp4";
import repetition from "../assets/images/repetition.mp4";
import colourCount from "../assets/images/colour-count.mp4";

import pathfinderImage from "../assets/images/pathfinder.png";
import perimeterImage from "../assets/images/perimeter.png";
import verticalDropImage from "../assets/images/vertical-drop.png";
import timeFreezeImage from "../assets/images/time-freeze.png";
import circumventImage from "../assets/images/circumvent.png";
import identicalImage from "../assets/images/identical.png";
import stopZoneImage from "../assets/images/stop-zone.png";
import calculateImage from "../assets/images/calculate.png";
import replicateImage from "../assets/images/replicate.png";
import avoidImage from "../assets/images/avoid.png";
import dimensionImage from "../assets/images/dimension.png";
import exactImage from "../assets/images/exact.png";
import timeSplitImage from "../assets/images/time-split.png";
import repetitionImage from "../assets/images/repetition.png";
import colourCountImage from "../assets/images/colour-count.png";

import create from "zustand";

export const useStore = create((set) => ({
  games: [
    {
      id: 1,
      offset: -75,
      name: "PATHFINDER",
      link: "/pathfinder",
      type: "mouse",
      simplify:
        "2 of the correct points in the path will be revealed and the path will be shown 1 additional time. ",
      controls:
        "The contestant makes a wrong move. Note: The path will not be shown again if the game is retried.",
      explanation:
        "After pressing START, The Cube floor will display a path of arrows from a red starting box to a gold end zone box. The arrow in each square points to the position of the next square in the route. The path will then disappear. The contestant must retrace the path by using the mouse or touchscreen to click on the next step in the route.",
      steps: ["- Memorize pathway", "- Retrace route", "- Win"],
      video: pathfinder,
      image: pathfinderImage,
    },
    {
      id: 2,
      offset: -75,

      name: "PERIMETER",
      link: "/perimeter",
      type: "button",
      controls: "The contestant stops the red square outside the gold box.",
      simplify: "The speed of the red square will decrease.",
      explanation:
        "After pressing START, a solid red square will travel around the perimeter of The Cube. The contestant must press the STOP button to halt the red square, stopping it completely inside the gold box.",
      steps: ["- Follow red square", "- Stop inside red box", "- Win"],
      video: perimeter,
      image: perimeterImage,
    },
    {
      id: 3,
      name: "VERTICAL DROP",
      link: "/vertical-drop",
      type: "button",
      controls:
        "The contestant stops the red square and it does not fall and land completely inside the gold box.",
      simplify: "The speed of the red square will decrease.",
      explanation:
        "After pressing START, a solid red square will travel horizontally across The Cube. The contestant must press the STOP button to stop the red square when it is directly above the gold square.",
      steps: [
        "- Follow red square",
        "- Stop directly above the red box",
        "- Win",
      ],
      offset: -75,
      video: verticalDrop,
      image: verticalDropImage,
    },
    {
      id: 4,
      name: "TIME FREEZE",
      link: "/timefreeze",
      type: "button",
      controls: "The contestant stops the timer outside the margin of error.",
      simplify: "The margin of error will increase to 0.5 seconds either side.",
      explanation:
        "After pressing START, the clock on The Cube floor will commence, but will not be visible. The contestant must press the STOP button to stop the timer at 10 seconds with only a 0.25 second margin of error either side.",
      steps: ["- Start the count", "- Stop the time in 30 seconds", "- Win"],
      offset: -75,
      video: timeFreeze,
      image: timeFreezeImage,
    },
    {
      id: 5,
      name: "CIRCUMVENT",
      link: "/circumvent",
      type: "arrow-buttons",
      controls:
        "The contestant does not get the blue square completely into the home square in the allotted time. The blue square touches a red square.",
      simplify: "The time to complete the game will increase.",
      explanation:
        "The contestant will have 15 seconds to move the blue square up to the home box at the top of the screen avoiding all the small red squares. Use the “W” and “S” keys for vertical movement and the “<” and “>” keys for horizontal movement, or tap on the touch screen arrow buttons.",
      steps: [
        "- Move your block by working together",
        "- dodge the red squares",
        "- Get inside the home block before the countdown",
      ],
      offset: -75,
      video: circumvent,
      image: circumventImage,
    },
    {
      id: 6,
      name: "IDENTICAL",
      link: "/identical",
      type: "button",
      controls:
        "The contestant does not identify the identical match before it disappears. The button is pressed when a match is not present.",
      simplify: "The time the squares are visible will increase.",
      explanation:
        "The floor of The Cube is split in two. After pressing START, a number of squares will appear on both sides before disappearing. The contestant must wait until the number of squares on both sides are identical and press the STOP button.",
      steps: [
        "- spot the amount of blocks on each side",
        "- Stop when the amount of blocks are identical",
        "- Win",
      ],
      offset: -75,
      video: identical,
      image: identicalImage,
    },
    {
      id: 7,
      name: "STOPZONE",
      link: "/stopzone",
      type: "button",
      controls:
        "The contestant does not stop the blue fill inside the target zone. The blue fill reaches the edge of The Cube.",
      simplify: "The target zone will increase in size.",
      explanation:
        "After pressing START, the floor of The Cube will start to fill a blue colour at a constant speed. This will disappear part way across the floor. The contestant has to press the STOP button when they think the blue fill is inside the target zone indicated by the two red horizontal lines.",
      steps: ["- follow the blue line", "- Stop  in the target zone ", "- Win"],
      offset: -75,
      video: stopZone,
      image: stopZoneImage,
    },
    {
      id: 8,
      name: "CALCULATE",
      link: "/calculate",
      type: "input",
      controls:
        "The contestant fails to enter the correct red square total at the end of the sequence.",
      simplify: "The speed of the sequence will be slower.",
      explanation:
        "After pressing START, a series of red squares will move across The Cube. The contestant must count the total number of squares that appear and enter that number into the box which appears at the end of the sequence.",
      steps: [
        "- Count the red squares",
        "- answer with the right amount of squares",
        "- Win",
      ],
      offset: -75,
      video: calculate,
      image: calculateImage,
    },
    {
      id: 9,
      name: "REPLICATE",
      link: "/replicate",
      type: "button",
      differentText: "ENTER",
      controls:
        "The contestant fails to enter the correct colour for all the boxes.",
      simplify: "2 of the boxes will remain the correct colour.",
      explanation:
        "After pressing START, the floor of The Cube will show a series of red and blue boxes before disappearing. The contestant must replicate the correct colour sequence by clicking on each box: once to turn it red, twice to turn it blue, or three times to return to blank, then click on the ENTER button.",
      steps: [
        "- Count the red squares",
        "- answer with the right amount of squares",
        "- Win",
      ],
      offset: -75,
      video: replicate,
      image: replicateImage,
    },
    {
      id: 10,
      name: "AVOID",
      link: "/avoid",
      type: "button",
      differentText: "MOVE",
      controls: "The contestant makes contact with the red bars.",
      simplify: "The speed of the red bars will decrease.",
      explanation:
        "After pressing START, the contestant must click the MOVE button to moves the blue square across the floor to the target zone at the top of the screen, whilst avoiding the moving red bars.",
      steps: [
        "- Count the red squares",
        "- answer with the right amount of squares",
        "- Win",
      ],
      offset: -75,
      video: avoid,
      image: avoidImage,
    },
    {
      id: 11,
      name: "DIMENSION",
      link: "/dimension",
      type: "button",
      explanation:
        "The floor of The Cube shows a narrow blue diamond shape. After pressing START, a large red diamond will appear covering the floor. This diamond will steadily decrease in size. The contestant must click the STOP button to stop the red diamond shrinking when they think its size is within the outer and inner edges of the blue diamond.",
      controls:
        "The contestant does not stop the red diamond inside the blue diamond’s outer and inner edges. The red diamond completely disappears.",
      simplify: "The size of the blue diamond increases.",
      offset: -75,
      video: dimension,
      image: dimensionImage,
    },
    {
      id: 12,
      name: "EXACT",
      link: "/exact",
      type: "button",
      differentText: "PRESS",
      controls:
        "The contestant fails to click the button exactly the required number of times within the time limit.",
      explanation:
        "After pressing START, the contestant must click the PRESS button exactly 25 times within 5 seconds. The timer starts to count down on the first click of the PRESS button.",
      simplify: "The time to complete the task increases.",
      offset: -75,
      video: exact,
      image: exactImage,
    },
    {
      id: 13,
      name: "TIMESPLIT",
      link: "/timesplit",
      type: "button",
      controls: "The contestant fails to stop the clock at exactly 5.00",
      explanation:
        "After pressing START, the clock on The Cube floor commences counting. The contestant must click the STOP button when the clock shows exactly 5.00",
      simplify: "The Cube will allow a small 0.2 second margin of error.",
      offset: -75,
      video: timeSplit,
      image: timeSplitImage,
    },
    {
      id: 14,
      name: "REPETITION",
      link: "/repetition",
      explanation:
        "The Cube is split into a 4x4 grid. After pressing START, 1 square will turn red, then back to white, then another and another. The contestant must click the STOP button the first time a grid square turns red for a second time.",
      type: "button",
      controls:
        "The contestant fails to identify the repetition before the repeated grid square disappears.",
      simplify: "Some of the grid squares will be blocked off.",
      offset: -75,
      video: repetition,
      image: repetitionImage,
    },
    {
      id: 15,
      name: "COLOUR COUNT",
      link: "/colour-count",
      explanation:
        "After pressing START, the floor of The Cube will turn red or blue. This indicates the colour of the squares the contestant must count on the  screen which will follow. After the squares have disappeared the contestant must enter their count into the box which appears at the end of the sequence.",
      type: "input",
      controls:
        "The contestant fails to enter the exact total of squares of the specified colour.",
      simplify: "The time to complete the task increases.",
      steps: false,
      offset: -75,
      video: colourCount,
      image: colourCountImage,
    },
  ],
  selectedGame: undefined,
  setSelectedGame: (data) => {
    set({ selectedGame: data });
  },
  soundSettingWin: 0.2,
  soundSettingFail: 0.2,
  soundSettingGame: 0.2,
  soundSettingOpening: 0.2,
  soundSettingClick: 0.3,
  granted: true,
  touchDevice: false,
  setTouchDevice: (data) => set({ touchDevice: data }),
  setGranted: (data) => set({ granted: data }),
  setSoundSettingWin: (data) => set({ soundSettingWin: data }),
  setSoundSettingFail: (data) => set({ soundSettingFail: data }),
  setSoundSettingGame: (data) => set({ soundSettingGame: data }),
  setSoundSettingOpening: (data) => set({ soundSettingOpening: data }),
  setSoundSettingClick: (data) => set({ soundSettingClick: data }),
}));
