import create from "zustand";

export const useReplicate = create((set, get) => ({
  boxes: [
    { x: "10%", y: "10%" },
    { x: "25%", y: "25%" },
    { x: "7%", y: "30%" },
    { x: "12%", y: "45%" },
    { x: "24%", y: "60%" },
    { x: "8%", y: "74%" },
    { x: "23%", y: "80%" },
    { x: "43%", y: "80%" },
    { x: "53%", y: "65%" },
    { x: "40%", y: "51%" },
    { x: "45%", y: "36%" },
    { x: "30%", y: "5%" },
    { x: "50%", y: "4%" },
    { x: "60%", y: "20%" },
    { x: "63%", y: "38%" },
    { x: "68%", y: "55%" },
    { x: "78%", y: "70%" },
    { x: "65%", y: "85%" },
    { x: "80%", y: "17%" },
    { x: "83%", y: "40%" },
  ],
  result: undefined,
  boxCount: 0,
  boxCountFilled: 0,
  setResultReplicate: (data) => set({ result: data }),
  setBoxCount: (data) => set({ boxCount: data === 0 ? 0 : get().boxCount + 1 }),
  setBoxCountFilled: (data) => set({ boxCountFilled: data }),
}));
