import React, { useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";

import { BigHeader } from "../atoms/typography";
import { theme } from "../../theme";
import { AnimatePresence, motion } from "framer-motion";
import { MiddleButton } from "../atoms/Button";

const GridcellTimeFreeze = ({
  started,
  startGame,
  time,
  stop,
  result,
  resultFeedback,
  setresultFeedback,
}) => {
  let centiseconds = ("0" + (Math.floor(time / 10) % 100)).slice(-2);
  let seconds = ("0" + (Math.floor(time / 1000) % 60)).slice(-2);

  useEffect(() => {
    result !== undefined
      ? setTimeout(() => {
          setresultFeedback(result);
        }, 2000)
      : setresultFeedback(undefined);
  }, [result]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: theme.colors.gradient,
      }}
    >
      {resultFeedback !== undefined && (
        <video
          autoPlay
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            zIndex: 5,
          }}
          loop
          muted
          playsInline
        >
          <source
            src={`/${resultFeedback ? "win" : "lose"}.mp4`}
            type="video/mp4"
          />
        </video>
      )}

      <Grid
        container
        style={{ width: "100%", height: "100%", textAlign: "center" }}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}>
          <BigHeader
            style={{
              color: theme.colors.primary,
              width: "70%",
              textAlign: "center",
              fontSize: "5vw",
              padding: "1rem",
              border: `${theme.colors.primary} 10px solid`,
            }}
          >
            <Grid container justify="center">
              <AnimatePresence exitBeforeEnter>
                {!started ? (
                  <React.Fragment key="seconds">
                    <motion.div
                      style={{ display: "flex" }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <Grid item>
                        <span>{seconds}</span>
                      </Grid>
                      <Grid item>&nbsp;.&nbsp;</Grid>
                      <Grid item>
                        <span>{centiseconds}</span>
                      </Grid>
                    </motion.div>
                  </React.Fragment>
                ) : (
                  <React.Fragment key="?">
                    <motion.div
                      style={{ display: "flex" }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <Grid item>
                        <span>....</span>
                      </Grid>
                      <Grid item>&nbsp;.&nbsp;</Grid>
                      <Grid item>
                        <span>....</span>
                      </Grid>
                    </motion.div>
                  </React.Fragment>
                )}
              </AnimatePresence>
            </Grid>
          </BigHeader>
        </Grid>
      </Grid>
    </div>
  );
};

export default GridcellTimeFreeze;
