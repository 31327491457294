import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion, AnimatePresence } from "framer-motion";

import InterfaceGameInfo from "../molecules/InterfaceGameInfo";
import InterfaceHeader from "../molecules/InterfaceHeader";
import InterfaceControls from "../molecules/InterfaceControls";
import InterfaceActions from "../molecules/InterfaceActions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useStore } from "../../store";
import { ButtonText, Text } from "../atoms/typography";

const GameInterface = ({
  open,
  setOpen,
  simplify,
  simplified,
  startGame,
  result,
  started,
  setShowControls,
}) => {
  const { selectedGame } = useStore();
  const { lg } = useWindowDimensions();

  return (
    <AnimatePresence>
      <div style={{ height: "100%" }}>
        {!open && (
          <motion.div
            key="close"
            onClick={() => setOpen(true)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <ButtonText
              style={{ fontFamily: "blair", margin: 0 }}
              fontSize="0.8em"
              light
            >
              INFO
            </ButtonText>
          </motion.div>
        )}
        {open && (
          <Grid
            key="open"
            container
            justify={open ? "center" : "space-between"}
            direction="column"
            alignItems="center"
          >
            <>
              <Grid
                style={{
                  width: "100vw",
                  marginBottom: "1em",
                  marginTop: "1em",
                }}
                item
                xs={10}
              >
                <InterfaceHeader
                  setShowControls={setShowControls}
                  setOpen={setOpen}
                  open={open}
                  selectedGame={selectedGame}
                />
              </Grid>
              <Grid item style={{ marginBottom: "0.5em" }} xs={10}>
                <InterfaceGameInfo key="info" selectedGame={selectedGame} />
              </Grid>
              <Grid
                item
                style={{ width: "100%", marginBottom: "0.5em" }}
                xs={10}
              >
                <InterfaceControls key="controls" selectedGame={selectedGame} />
              </Grid>
              <Grid
                item
                style={{ width: "100%", marginBottom: "0.5em" }}
                xs={10}
              >
                <InterfaceActions
                  simplified={simplified}
                  started={started}
                  result={result}
                  startGame={() => startGame()}
                  simplify={() => simplify()}
                  key="actions"
                  selectedGame={selectedGame}
                />
              </Grid>
            </>
          </Grid>
        )}
      </div>
    </AnimatePresence>
  );
};

export default GameInterface;
