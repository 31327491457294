import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Grid from "@bit/mui-org.material-ui.grid";

import { generateSequence } from "../../gameFunctions/calculate";
import { theme } from "../../theme";
import { AnimatePresence } from "framer-motion";
import GameNumberInput from "../atoms/GameNumberInput";

export const Box = styled.div`
  width: 12%;
  height: 12%;
  position: relative;
  cursor: pointer;
  background: rgb(190, 223, 255);
  background: radial-gradient(
    circle,
    rgba(190, 223, 255, 1) 0%,
    rgba(223, 240, 255, 1) 68%,
    rgba(255, 255, 255, 1) 100%
  );
  float: left;
  display: flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #2a1a25;
  box-sizing: border-box;
  margin: 1%;
`;

const Cell = ({ sequence, index, started }) => {
  return sequence && started ? (
    <Box
      style={{
        opacity: sequence.includes(index) ? 1 : 0,
        background: sequence.includes(index)
          ? theme.colors.primary
          : theme.colors.primary,
      }}
    />
  ) : null;
};

const GridcellCalculate = ({
  started,
  simplified,
  setResult,
  result,
  setStarted,
  answering,
  setAnswering,
  showAnswer,
  setShowAnswer,
  sequenceRound,
  setsequenceRound,
  roundRef,
}) => {
  const [sequenceObject] = useState(generateSequence());
  const [number1, setNumber1] = useState(0);
  const [number2, setNumber2] = useState(0);

  useEffect(() => {
    if (showAnswer) {
      if (parseInt(`${number1}${number2}`) === sequenceObject.total)
        setResult(true);
      else setResult(false);
      setStarted(false);
    }
  }, [showAnswer]);

  useEffect(() => {
    if (started) {
      const int = setInterval(
        () => {
          if (roundRef.current === sequenceObject.sequence.length) {
            clearInterval(int);
            setAnswering(true);
          } else {
            roundRef.current++;
            setsequenceRound((round) => round + 1);
          }
        },
        simplified ? 1200 : 0.7 * 1000
      );
    }
  }, [started, simplified]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: theme.colors.gradient,
      }}
    >
      {Array.from(Array(49).keys()).map((i) => (
        <Cell
          started={started}
          key={i}
          sequence={sequenceObject.sequence[sequenceRound]}
          index={i}
        />
      ))}
      <Grid
        container
        style={{
          width: "100%",
          height: "100%",
          textAlign: "center",
          position: "absolute",
        }}
        alignItems="center"
        justify="center"
      >
        {answering && (
          <Grid item>
            <AnimatePresence exitBeforeEnter>
              <GameNumberInput
                number1={number1}
                number2={number2}
                setNumber2={setNumber2}
                setNumber1={setNumber1}
                enter={() => {
                  if (answering) setShowAnswer(true);
                }}
              />
            </AnimatePresence>
          </Grid>
        )}
      </Grid>
      {result !== undefined && (
        <video
          autoPlay
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            zIndex: 5,
          }}
          loop
          muted
          playsInline
        >
          <source src={`/${result ? "win" : "lose"}.mp4`} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default GridcellCalculate;
