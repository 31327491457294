import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const Button = styled(motion.button)`
  border: ${(props) => `${props.theme.colors.secondary} solid 2px`};
  color: white;
  padding: ${(props) => props.padding || "20px 30px"};
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: ${(props) => `${props.theme.colors.secondary}`};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: ${(props) => props.width || ""};
  margin: ${(props) => props.margin || "0 0 0 0"};
  box-shadow: 0 5px 0 ${(props) => props.theme.colors.secondaryDarker};
  box-sizing: border-box;
  &:active {
    box-shadow: none;
    transform: translateY(5px);
  }

  &:focus {
    outline: 0;
  }

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.round &&
    css`
      padding: 1em;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    `}

  ${(props) =>
    props.small &&
    css`
      padding: 10px 15px;
    `}
`;

export const StartButton = styled.button`
  border: ${(props) => `${props.theme.colors.secondary} solid 2px`};
  color: white;
  padding: 20px 30px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.secondary};
  display: inline-block;
  font-size: 16px;
  width: ${(props) => props.width || ""};
  margin: ${(props) => props.margin || "0 0 0 0"};

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;

export const ThreeButton = styled(motion.a)`
  display: inline-block;
  padding: 20px 40px;
  color: white;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  vertical-align: middle;
  text-decoration: none;
  font-size: 1vw;
  transition: all 0.5s;
  background-color: #ae1f23;
  cursor: pointer;
  z-index: 10;
  font-family: cube;

  &::before {
    position: absolute;
    content: "";
    transition: all 0.3s;
    bottom: -5px;
    height: 5px;
    width: 100%;
    left: 3px;
    transform: skewX(45deg);
    background-color: #650c0e;
  }

  &::after {
    position: absolute;
    content: "";
    transition: all 0.3s;
    right: -5px;
    height: 100%;
    width: 5px;
    bottom: -3px;
    transform: skewY(45deg);
    background-color: #650c0e;
  }

  &:active {
    margin-left: 3px;
    margin-top: 3px;
    &::before {
      bottom: -1px;
      height: 1px;
      left: 0px;
    }

    &::after {
      right: -1px;
      width: 1px;
      bottom: -0px;
    }
  }
`;
