import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import useSound from "use-sound";

import { HeaderFour } from "./typography";
import { useEffect } from "react";
import { useStore } from "../../store";
import clickSound from "../../assets/audio/click.wav";
import arrow from "../../assets/images/angle-right.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Grid from "@bit/mui-org.material-ui.grid";
import { theme } from "../../theme";

const GameBox = ({ i, game, preview }) => {
  const ref = useRef();
  const history = useHistory();
  const [hoverState, setHoverState] = useState(false);
  const { setSelectedGame, soundSettingClick } = useStore();
  const [click] = useSound(clickSound, { volume: soundSettingClick });
  const vidRef = useRef(null);
  const { small, xs } = useWindowDimensions();

  useEffect(() => {
    if (!small) {
      hoverState ? vidRef.current.play() : vidRef.current.pause();
    }
  }, [hoverState]);

  return (
    <>
      <motion.div
        ref={ref}
        onMouseOver={() => setHoverState(true)}
        onMouseLeave={() => setHoverState(false)}
        onTouchEnd={() => click()}
        onClick={() => {
          history.push(game.link);
          setSelectedGame(game);
        }}
        style={{
          y: [11, 5, 8, 14, 2].includes(game.id) && !xs ? 200 : 0,
          boxShadow: "0 7px 0 #7A1518",
          border: `5px ${theme.colors.primary} solid`,
          minHeight: "130px",
          backgroundColor: "rgba(0,0,0,0.5)",
          cursor: "pointer",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: preview ? "auto" : "none",
          padding: "0",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {small ? (
          <img src={game.image} width="100%" height="auto" />
        ) : (
          <motion.video
            ref={vidRef}
            style={{
              width: "100%",
              height: "100%",
              zoom: "10px",
              scale: 1.07,
            }}
            loop
            muted
            playsInline
            poster={game.image}
          >
            <source src={game.video} type="video/mp4" />
          </motion.video>
        )}
        <motion.div
          style={{
            bottom: 0,
            right: 0,
            width: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 40,
            position: "absolute",
            color: "#fff",
            backgroundColor: theme.colors.secondary,
          }}
        >
          <motion.span style={{ y: 3 }}>{game.id}</motion.span>
        </motion.div>
      </motion.div>

      <Grid
        onMouseOver={() => setHoverState(true)}
        onMouseLeave={() => setHoverState(false)}
        onTouchEnd={() => click()}
        onClick={() => {
          history.push(game.link);
          setSelectedGame(game);
        }}
        component={motion.div}
        style={{
          cursor: "pointer",
          marginTop: "1em",
          y: [11, 5, 8, 14, 2].includes(game.id) && !xs ? 200 : 0,
        }}
        justify="center"
        alignItems="center"
        container
      >
        <Grid item xs={hoverState ? false : 12}></Grid>
        <Grid item>
          <HeaderFour
            style={{
              display: "flex",
              alignItems: "center",
              letterSpacing: 1.2,
            }}
            initial={{ fontSize: small ? "1rem" : "1.4rem" }}
            fontSize={small ? "1rem" : "1.4rem"}
            light
          >
            {game.name}{" "}
            {!xs && (
              <motion.img
                animate={{ x: hoverState ? 5 : 0 }}
                style={{ marginLeft: 10, y: -3 }}
                width={12}
                src={arrow}
              />
            )}
          </HeaderFour>
        </Grid>
      </Grid>
    </>
  );
};

export default GameBox;
